<template>
  <div>
    <Header title="" description="" height="16" />
    <div
      v-if="event"
      class="container mx-auto px-5 md:px-0 md:max-w-[775px] my-12"
    >
      <p class="text-dateText my-3">{{ $t("events.eventDate") }}</p>
      <h1 class="text-btn text-3xl font-bold tracking-wide leading-relaxed">
        {{ event.event_date }}
      </h1>
      <div class="mt-12">
        <h3 class="text-footerBg text-2xl">
          {{ $i18n.locale == "EN" ? event.title_en : event.title_ar }}
        </h3>
        <p
          class="text-newsDescription text-lg tracking-wide leading-relaxed mt-5"
        >
          {{ $i18n.locale == "EN" ? event.description_en : event.description_ar }}
        </p>
        <form
          @submit.prevent="submit"
          class="relative border border-eventFormBorder p-8 mt-24"
        >
          <span class="text-footerBg text-2xl absolute -top-4 bg-white">{{
            $t("events.regForm")
          }}</span>
          <div class="w-full md:w-3/4 mx-auto">
            <input
              class="border focus:ring-2 py-3 px-5 w-full block my-5"
              type="text"
              id="name"
              name="name"
              v-model="form.name"
              @blur="$v.form.name.$touch()"
              :placeholder="$t('events.form.name')"
              :class="
                $v.form.name.$error ? 'border-red-500' : 'border-searchBorder'
              "
            />
            <input
              class="border focus:ring-2 py-3 px-5 w-full block my-5"
              type="email"
              id="email"
              name="email"
              v-model="form.email"
              @blur="$v.form.email.$touch()"
              :placeholder="$t('events.form.email')"
              :class="
                $v.form.email.$error ? 'border-red-500' : 'border-searchBorder'
              "
            />
            <input
              class="border focus:ring-2 py-3 px-5 w-full block my-5"
              type="number"
              id="phone_number"
              name="phone_number"
              v-model="form.phone_number"
              @blur="$v.form.phone_number.$touch()"
              :placeholder="$t('events.form.phone')"
              :class="
                $v.form.phone_number.$error
                  ? 'border-red-500'
                  : 'border-searchBorder'
              "
            />
            <input
              class="hidden"
              ref="fileUpload"
              id="fileUpload"
              @change="fileUploadFunc"
              type="file"
              required
            />
            <button
              @click="uploadInputClick"
              @blur="$v.form.attach_file.$touch()"
              :class="
                $v.form.attach_file.$error
                  ? 'border-red-500'
                  : 'border-searchBorder'
              "
              class="border cursor-pointer w-full overflow-hidden outline-none focus:outline-none hover:bg-eventHover border-dashed my-5 flex items-center justify-center h-56"
            >
              <div v-if="!fileuploaded" class="text-center">
                <div
                  class="h-12 w-12 rounded-full flex items-center justify-center bg-uploadIcon mx-auto mb-3"
                >
                  <img
                    src="../assets/upload.svg"
                    alt="uplpad"
                    loading="lazy"
                    class="mx-auto object-cover"
                  />
                </div>
                <h4 class="text-dateText text-lg">
                  {{ $t("events.form.upload") }}
                </h4>
              </div>
              <img
                v-else
                src="../assets/checked.png"
                alt=""
                loading="lazy"
                class="h-24 w-24 object-cover"
              />
            </button>
            <textarea
              name="note"
              id="note"
              rows="4"
              v-model="form.note"
              :placeholder="$t('events.form.note')"
              class="border py-3 focus:ring-2 px-5 my-5 w-full block h-56 border-searchBorder"
            ></textarea>
            <p
              v-for="err in errorMsg"
              class="my-5 bg-red-50 text-red-500 border-red-500 py-2 font-medium tracking-wide leading-relaxed px-5 w-full border rounded-md"
              :key="err"
            >
              {{ err }}
            </p>
            <p
              v-if="successMsg"
              class="my-5 bg-green-600 text-white border-green-600 py-2 font-medium tracking-wide leading-relaxed px-5 w-full border rounded-md"
            >
              {{ successMsg }}
            </p>
            <button
              :disabled="loading"
              @click.prevent="submit"
              class="bg-btn focus:outline-none focus:ring-2 block text-xl w-full p-5 text-white my-5"
            >
              <i
                v-if="loading"
                class="bx bx-loader-circle text-2xl bx-spin"
              ></i>
              <i v-else-if="success" class="bx bx-check-double text-2xl"></i>
              <span v-else> {{ $t("events.form.submit") }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div v-else class="my-12 text-center mx-auto">
      <i class="text-5xl text-primary bx bx-loader-circle bx-spin"></i>
    </div>
  </div>
</template>
<script>
import Header from "@/components/layout/Header";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        attach_file: "",
        phone_number: "",
        note: "",
      },
      event: null,
      errorMsg: [],
      success: false,
      loading: false,
      fileuploaded: false,
      successMsg: "",
    };
  },
  components: {
    Header,
  },
  validations: {
    form: {
      name: { required },
      email: { required },
      attach_file: { required },
      phone_number: { required },
    },
  },
  created() {
    this.getEvent().then((event) => {
      this.loading = false;
      this.event = event;
    });
  },
  methods: {
    getEvent() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`/api/events/${this.$route.params.id}`)
          .then(({ data }) => {
            resolve(data.data);
          })
          .catch((err) => reject(err.response));
      });
    },
    uploadInputClick() {
      this.$refs.fileUpload.click();
    },
    fileUploadFunc(e) {
      if (!e.target.files.length) return;
      this.form.attach_file = e.target.files[0];
      this.fileuploaded = true;
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error || this.$v.form.$pending) {
        this.errorMsg = [this.$t("events.form.error")];
        setTimeout(() => {
            this.errorMsg = [];
          }, 5000)
        return;
      }
      this.loading = true;
      this.errorMsg = [];
      const formData = new FormData();
      formData.append("scientific_event_id", this.$route.params.id);
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      this.$store
        .dispatch("participant", formData)
        .then(() => {
          this.loading = false;
          this.success = true;
          this.form = {
            name: "",
            email: "",
            file: "",
            phone_number: "",
            note: "",
          };
          this.fileuploaded = false;
          this.$v.form.$reset();
          this.errorMsg = [];
          this.successMsg = this.$t("events.successMsg");
          setTimeout(() => {
            this.success = false;
          }, 10000);
        })
        .catch((err) => {
          this.errorMsg = err.data;
          this.loading = false;
          setTimeout(() => {
            this.errorMsg = [];
          }, 5000);
        });
    },
  },
};
</script>